import React, { useState, useEffect } from "react"
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { EventBanner } from "../../../components/event-banner"
import eventImage from "../../../assets/events/2022/GalaDinnerHeaderImage.jpg"
import { Formik, Form, FieldArray, Field } from "formik";
import * as yup from 'yup';
import BG from "../../../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"
import axios from "axios";
const FORM = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdp5EiJ2b6IkHJRi-FBeZVTvjQVrudw_2WhNX9z8rkb8Vb1sg/formResponse"
const GF_FirstName_Field = "entry.1319717536"
const GF_FamilyName_Field = "entry.1775151963"
const GF_Company_Field = "entry.769995232"
const GF_CompanyAddress_Field = "entry.1720523204"
const GF_Email_Field = "entry.1281834326"
const GF_GuestFirstName_Field = "entry.588553805"
const GF_GuestFamilyName_Field = "entry.369636498"
const GF_DietaryRequirements_Field = "entry.1500939158"

const urlParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
const UpdateGuests = () => {
  const CustomError = ({ errorText, show, index }) => {
    return (
      <> {show ? (
        <label className="form-field-error"> {isNaN(index)?'': `Guest ${index +1}`}  {errorText}</label>
      ) : null}
      </>
    )
  }
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formError,setFormError]=useState('Please add minimum one guest to proceed.');
  const [formData, setFormData] = useState({
    firstName: urlParams?.get('f') || '',
    familyName: urlParams?.get('l') || '',
    email: urlParams?.get('m') || '',
    company: urlParams?.get('c') || '',
    companyAddress: urlParams?.get('a') || '',
    guests: [{
      firstName: '',
      familyName: '',
      dietaryRequirements: ''
    }]
  });

  const GuestDetails = () => {
    const validationSchema = yup.object({

      firstName: yup
        .string()
        .required('Given Name(s) is required')
        .max(100),
      familyName: yup
        .string()
        .required('Family Name is required')
        .max(100),
      email: yup
        .string()
        .email('Invalid email')
        .required('Email is required'),
      company: yup
        .string()
        .required('Company is required')
        .max(50),
      companyAddress: yup
        .string()
        .required('Company Address is required')
        .max(100),
      guests: yup.array()
        .of(yup.object().shape(
          {
            firstName: yup.string().required('Given Name(s) required').max(100),
            familyName: yup.string().required('Family Name required').max(100)
          }
        ))

    });
    const updateMA = () => {
      axios
        .post('/api/mazda-notify');
    } 

    return (
      <Layout page="events">
        <SEO title="Events" />
        <div
          id="events"
          style={{
            background: `url(${BG}) repeat`
          }}>
          {submitSuccess ? <>
            <EventBanner BannerText="YOUR GUEST DETAILS ARE SUCCESSFULLY SUBMITTED" />
            <div className="center columns">
              <div className=" column "><br/>
                <br /><br />
                We look forward to welcoming you to the gala dinner.
                <br /><br />
                
                Mazda Foundation Gala Dinner<br />
                Palladium Ballroom <br />
                Crown Casino Melbourne <br /><br />
                Dress : Black Tie <br />
                Arrival Time : 7:00 pm<br /><br />
                <button className="GreyButton" onClick={() => window.location.href = '/'} >BACK TO HOMEPAGE</button><br /><br />

              </div>
            </div>

          </> : <>
            <EventBanner BannerText="TELL US ABOUT YOUR GUEST" BannerImage={eventImage} />
            <div className="one_half column" >
              <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  setFormData(values);
                  if(values.guests && values.guests.length<1)
                  {
                   
                    setFormError('Please add minimum one guest to proceed.');      
                    actions.setSubmitting(false);              
                    return;
                  }
                  
                  values.guests.map((guest, index) => {
                    const submitFormData = new FormData()
                    submitFormData.append(GF_FirstName_Field, values.firstName)
                    submitFormData.append(GF_FamilyName_Field, values.familyName)
                    submitFormData.append(GF_Company_Field, values.company)
                    submitFormData.append(GF_CompanyAddress_Field, values.companyAddress)
                    submitFormData.append(GF_Email_Field, values.email)
                    submitFormData.append(GF_GuestFirstName_Field, guest.firstName)
                    submitFormData.append(GF_GuestFamilyName_Field, guest.familyName)
                    submitFormData.append(GF_DietaryRequirements_Field, guest.dietaryRequirements)

                    axios
                      .post(FORM, submitFormData)
                      .then(response => {
                        setSubmitSuccess(true);

                      })
                      .catch(error => {
                        setSubmitSuccess(true);

                      })
                  });
                  updateMA();
                }

                }
                render={({ errors, values, touched }) => {

                  return (
                    <Form>
                      <div className="  ">

                        <div className="container">

                          <FieldArray
                            name="guests"
                            render={arrayHelpers => (
                              <div className="columns"><br/>
                                <div className="column"><br/><h3>CONTACT INFORMATION</h3> </div>
                                <div className="one_half column">

                                  <p> Given Name(s)<br />
                                    <Field
                                      name='firstName'
                                      label='First Name'
                                      margin='normal'
                                      type="text"
                                    />
                                    <CustomError errorText={errors.firstName} show={touched.firstName} />
                                  </p>
                                </div>
                                <div className="one_half column">
                                  <p> Family Name<br />
                                    <Field
                                      name='familyName'
                                      label='Family Name'
                                      margin='normal'
                                      type="text"
                                    />
                                    <CustomError errorText={errors.familyName} show={touched.familyName} />
                                  </p>
                                </div>
                                <div className="one_half column">
                                  <p className="position-margin">Company<br />
                                    <Field
                                      name='company'
                                      label='Company'
                                      margin='normal'
                                      type="text"
                                    />
                                    <CustomError errorText={errors.company} show={touched.company} />
                                  </p><br/>
                                  <p>Company Address<br />
                                    <Field
                                      name='companyAddress'
                                      label='Company Address'
                                      margin='normal'
                                      type="text"
                                    />
                                    <CustomError errorText={errors.companyAddress} show={touched.companyAddress} />
                                  </p><br/>
                                  <p>Email used for payment<br />
                                    <Field
                                      name='email'
                                      label='email'
                                      margin='normal'
                                      type="text"
                                    />
                                    <CustomError errorText={errors.email} show={touched.email} />
                                  </p><br/>
                                </div>
                                <div className="column"><br />
                                  <hr className="guests-add-hr" />
                                </div>
                                <div className="column">
                                  <h3>GUEST DETAILS</h3>
                                  <p className="form-field-error">{values.guests &&  values.guests.length<1  &&  formError}</p>
                                </div>
                                {values.guests.map((guests, index) => (
                                  <div key={index} className="column ">

                                    <div className="guest-add-section">
                                      <div >
                                        <div className="columns ">
                                          <div className="one_half column">
                                            <p className="position-margin"> Given Name(s)<br />
                                              <Field name={`guests[${index}].firstName`} type="text" />
                                              {errors.guests?.length > 0 ? <CustomError index={index} errorText={errors.guests[index]?.firstName} show={touched?.guests?.length > 0 && errors.guests[index]?.firstName} /> : <></>}
                                            </p>
                                          </div>
                                          <div className="one_half column">
                                            <p  className="position-margin"> Family Name<br />
                                              <Field name={`guests.${index}.familyName`} type="text" />
                                              {errors.guests?.length > 0 ? <CustomError index={index} errorText={errors.guests[index]?.familyName} show={touched?.guests?.length > 0 && errors.guests[index]?.familyName} /> : <></>}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div>

                                        <div className="columns dietary-req">
                                          <div className="one_half column">
                                            <p className="position-margin">Dietary Requirements (Optional)</p>
                                            <Field name={`guests.${index}.dietaryRequirements`} type="text" />
                                            {errors.guests?.length > 0 ? errors.guests[index]?.dietaryRequirements : <></>}
                                          </div>
                                          <div className="one_half column remove-guest-head ">
                                            {<a className="remove-guest add-guest-text" href='#' onClick={(e) => { e.preventDefault(); arrayHelpers.remove(index) }}>
                                              Remove Guest
                                            </a>}

                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                ))}
                              
                                <div className="column">  
                                  <a href='#' className="add-guest-text"

                                    onClick={(e) => { e.preventDefault(); arrayHelpers.push({ firstName: '', familyName: '', dietaryRequirements: '' }) }}
                                  >
                                    Add {values.guests.length > 0 ? 'Another' : ''} guest
                                  </a><br/>  <br/>
                                </div>


                              </div>
                            )}
                          />
                          <div className="columns">                              
                            <div className="one_half column">                            
                              <button className="GreyButton" type="submit">CONFIRM & FINISH</button>
                            </div>

                          </div>

                        </div>
                      </div>

                    </Form>)
                }}

              />
            </div></>}
        </div>
      </Layout>)


  }
  return <GuestDetails />
}
export default UpdateGuests